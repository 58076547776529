import { blanceVolumeDefault } from "@src/common/constants";
import { ITopState } from "@src/interface/ITopState";
import { actionTypes } from "./topActions";

const initialState: ITopState = {
  balance: blanceVolumeDefault,
  historyCash: 0,
  hidePopupOrder: false,
  current_rate: 104.703,
  dataLine: [],
  bidRate: 104.474,
  askRate: 104.511,
  isShownPopupWelcome: false
}
const topReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_BANLANCE: {
      const { balance } = action;
      return {
        ...state,
        balance: balance
      }
    }
    case actionTypes.RESET_TOP_STATE: {
      return {
        ...initialState,
        hidePopupOrder: state.hidePopupOrder,
        dataLine: state.dataLine,
        isShownPopupWelcome: state.isShownPopupWelcome
      }
    }
    case actionTypes.UPDATE_CURRENT_RATE: {
      const { current_rate, bid_rate, ask_rate } = action;
      return {
        ...state,
        current_rate: current_rate.toFixed(3),
        bidRate: bid_rate.toFixed(3),
        askRate: ask_rate.toFixed(3)
      }
    }
    case actionTypes.HIDE_POPUP_CONFIRM: {
      return {
        ...state,
        hidePopupOrder: true
      }
    }
    case actionTypes.OPEN_TRADE_SUCCESS: {
      const { profit, is_buy, cash, notional, start_price, last_price } = action;
      return {
        ...state,
        currentOrder: {
          is_buy,
          profit,
          cash,
          notional,
          start_price,
          last_price
        }
      }
    }
    case actionTypes.UPDATE_PROFIT_TRADE_SUCCESS: {
      const { profit, last_price } = action;
      return {
        ...state,
        currentOrder: {
          ...state.currentOrder,
          profit,
          last_price
        }
      }
    }
    case actionTypes.CLOSE_TRADE_SUCCESS: {
      const { profit, new_balance } = action;
      const newState = { ...state }

      return {
        ...newState,
        balance: new_balance,
        historyCash: profit
      }
    }
    case actionTypes.REMOVE_CURRENT_ORDER: {
      const newState = { ...state }
      delete newState["currentOrder"];

      return { ...newState };
    }
    case actionTypes.FAKE_NOW_PRICE_SUCCESS: {
      const { newData, bidRate, askRate } = action;

      return {
        ...state,
        dataLine: newData,
        bidRate,
        askRate
      }
    }
    case actionTypes.GET_TICK_LOG_SUCCESS: {
      return {
        ...state,
        dataLine: action.dataLine
      }
    }
    case actionTypes.GET_SINGLE_NOW_PRICE_SUCCESS: {
      const { askRate, bidRate, chartData } = action
      const newDataLine = [...state.dataLine]
      newDataLine.push(chartData)
      newDataLine.length > 200 && newDataLine.shift();

      return {
        ...state,
        dataLine: newDataLine,
        askRate,
        bidRate
      }
    }
    case actionTypes.UPDATE_POPUP_WELCOME: {
      return {
        ...state,
        isShownPopupWelcome: true
      }
    }
    default: return state;
  }
}

export default topReducer;
