import { handleDeposit, handleError, resultTrade } from "@src/common/utils";
import { ITickerLog } from "@src/interface/ITopState";
import { show } from "redux-modal";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { actionTypes, openTradeSuccess, updateTradeSuccess, closeTradeSuccess, updateTrade, getTickLogSuccess, getSingleNowPriceSuccess, updateCurrentRate } from "./topActions";
import { currentOrderSelector, balanceSelector } from "./topSelector";
import { topApiServices } from "./topServices";
import dayjs from 'dayjs';
import { show as showModal } from 'redux-modal';
import { updateIsLoading, updateIsShowedNoticeModal } from "@src/common/commonActions";
import { isShowedNoticeModalSelector } from "@src/common/commonSelector";
dayjs.locale('ja')

function* openTradeSaga(action: any) {
  try {
    const { is_buy, cash, start_price, last_price } = action;
    const notional = handleDeposit(cash)
    const result = resultTrade(last_price, start_price, notional, is_buy)
    yield put(openTradeSuccess(start_price, result, is_buy, cash, notional, last_price))

  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  }
}

function* closeTradeSaga(action: any) {
  try {
    const { profit } = action;
    const balance = yield select(balanceSelector)
    const new_balance = balance + profit
    yield put(closeTradeSuccess(profit, new_balance))
    yield put(show('WINLOSE_MODAL'))
  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  }
}

function* updateTradeSaga(action: any) {
  try {
    const { last_price } = action;
    const { is_buy, notional, start_price } = yield select(currentOrderSelector);
    const profit = resultTrade(last_price, start_price, notional, is_buy)
    yield put(updateTradeSuccess(profit, last_price))
  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  }
}

function* updateRateSaga(action: any) {
  try {
    const { current_rate } = action;
    const currentOrder = yield select(currentOrderSelector)
    if (currentOrder) yield put(updateTrade(current_rate))
  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  }
}

function* getTickLogSaga() {
  yield put(updateIsLoading(true))
  try {
    const isShowedNoticeModal = yield select(isShowedNoticeModalSelector)
    const { data } = yield call(topApiServices.getTickLog);
    const { tickers, is_demo_rate } = data;
    const dataLine = tickers.map((ticker: ITickerLog) => {
      const { date, bid_price, ask_price } = ticker;

      return {
        name: dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ').format('YYYY/MM/DD HH:mm:ss'),
        data: (bid_price + ask_price) / 2
      }
    })
    if (is_demo_rate && !isShowedNoticeModal) {
      yield put(showModal("NOTICE_MODAL"))
      yield put (updateIsShowedNoticeModal(true));
    }
    yield put(getTickLogSuccess(dataLine));
  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  } finally {
    yield put(updateIsLoading(false))
  }
}

function* getSingleNowPriceSaga() {
  try {
    const { data } = yield call(topApiServices.getSingleNowPrice)
    const { ticker } = data;
    const { bid_price, ask_price } = ticker;
    const close_price = (bid_price + ask_price) / 2;
    const dataChart = { name: dayjs().format("YYYY/MM/DD HH:mm:ss"), data: close_price }

    yield put(getSingleNowPriceSuccess(dataChart, bid_price, ask_price))
    yield put(updateCurrentRate(close_price, bid_price, ask_price))
  } catch (error) {
    handleError(error);
    yield put(show("NOTICE_ERROR_MODAL"));
  }
}

function* openTradeWatcher() {
  yield takeLatest(actionTypes.OPEN_TRADE, openTradeSaga)
}
function* closeTradeWatcher() {
  yield takeLatest(actionTypes.CLOSE_TRADE, closeTradeSaga)
}

function* updateTradeWatcher() {
  yield takeLatest(actionTypes.UPDATE_PROFIT_TRADE, updateTradeSaga)
}

function* updateRateWatcher() {
  yield takeLatest(actionTypes.UPDATE_CURRENT_RATE, updateRateSaga)
}

function* getTickLogWatcher() {
  yield takeLatest(actionTypes.GET_TICK_LOG, getTickLogSaga)
}

function* getSingleNowPriceWatcher() {
  yield takeLatest(actionTypes.GET_SINGLE_NOW_PRICE, getSingleNowPriceSaga)
}

export default function* topSaga() {
  yield all([
    openTradeWatcher(),
    closeTradeWatcher(),
    updateTradeWatcher(),
    updateRateWatcher(),
    getTickLogWatcher(),
    getSingleNowPriceWatcher()
  ])
}