import { appAxios } from '@src/app/appAxios';
import { AxiosResponse } from 'axios';

export const topApiServices = {
  getTickLog: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/ticker/log?limit=40');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },

  getSingleNowPrice: async() => {
    try {
      const { data }: AxiosResponse = await appAxios.get('/ticker/now');
      return data;
    } catch (error) {
      error.stack = new Error().stack;
      throw error;
    }
  },
}