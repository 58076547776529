import topReducer from '@src/top/topReducer';
import commonReducer from '@src/common/commonReducer';
import { combineReducers } from 'redux'
import { reducer as modal } from 'redux-modal';


const appReducer = combineReducers({
  modal,
  topState: topReducer,
  commonState: commonReducer
});

export default appReducer;