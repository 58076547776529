import { ICommonState } from "@src/interface/ICommonState";
import { actionTypes } from "./commonActions";

const initialState: ICommonState = {
  popperActive: "",
  isLoading: false,
  isShowedNoticeModal: false
}
const commonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_POPPER_ACTIVE: {
      const { popper_name } = action;
      return {
        ...state,
        popperActive: popper_name
      }
    }
    case actionTypes.UPDATE_IS_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isLoading
      }
    }
    case actionTypes.UPDATE_IS_SHOWED_NOTICE_MODAL: {
      return {
        ...state,
        isShowedNoticeModal: action.isShowedNoticeModal
      }
    }
    default: return state;
  }
}

export default commonReducer;
