import React, { useEffect } from 'react';
import appStore from '@src/app/appStore';
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import '../styles/globals.css';
import CusSentry from '@src/common/sentry';
import { isClient, unsupportedVersion } from '@src/common/utils';
import { useRouter } from 'next/router';
import Head from 'next/head';


function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    if (isClient() && unsupportedVersion()) {
      router.replace('/unsupported_version')
    }
  }, [])

  const persistor = persistStore(appStore);
  return (
    <>
    <Head>
       <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Head>
    <Provider store={appStore}>
      <PersistGate persistor={persistor} loading={null}>
        <Component {...pageProps} />
      </PersistGate>
    </Provider>
    </>
  )
}
MyApp.componentDidCatch = (error: { [key: string]: string }, errorInfo: { [key: string]: string }) => {
  const { Sentry } = CusSentry;
  Sentry.withScope(scope => {
    scope.setExtra("componentStack", errorInfo);
    Sentry.captureException(error);
  });
}
export default MyApp