import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import appReducer from './appReducer';
import appSaga from './appSaga';
import { AxiosError, AxiosResponse } from 'axios';
import appAxios from './appAxios';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Store } from 'redux';
import topReducer from '@src/top/topReducer';
import commonReducer from '@src/common/commonReducer';
import { reducer as modal } from 'redux-modal';
import { isClient } from '@src/common/utils';
import Router from 'next/router';

// axios middleware
const createAxiosMiddleware = (axios: any) => ({}: any) => {
  let errCount = 0;

  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      response.config.url === '/ticker/now' && errCount && (errCount = 0);
      return response;
    },
    function (error: AxiosError) {
      const { config, response } = error;
      const apiUrl = config.url ? config.url.split("?")[0].split("#")[0] : '';
      if(response && response.status === 403 && response.data && response.data.code) {
        return Promise.reject(error);
      }
      let isRender500Page = config && apiUrl === '/ticker/log'

      if (apiUrl === '/ticker/now' && response && response.status) {
        errCount++;
        isRender500Page = errCount === 5;
      }

      isRender500Page && Router.replace('/500')
      return Promise.reject(error);
    }

  );

  return (next: any) => (action: any) => next(action);
};

// Build app middleware
const sagaMiddleware = createSagaMiddleware();
const axiosMiddleware = createAxiosMiddleware(appAxios);

const bindMiddleware = (middleware: any) => {
  if (process?.env?.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const reduxCompose = compose(
  bindMiddleware([sagaMiddleware, axiosMiddleware])
);

let appStore: Store;

if (isClient()) {
  const { persistReducer } = require('redux-persist');
  const storage = require('redux-persist/lib/storage/session').default;

  const rootPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['modal', 'commonState']
  }

  const topPersistConfig = {
    key: 'top',
    storage: storage,
    blacklist: ['dataLine']
  }

  const rootReducer = combineReducers({
    topState: persistReducer(topPersistConfig, topReducer),
    commonState: commonReducer,
    modal
  })

  appStore = createStore(
    persistReducer(rootPersistConfig, rootReducer),
    reduxCompose
  );
} else {
  appStore = createStore(
    appReducer,
    reduxCompose
  );
}

// Run saga middleware
sagaMiddleware.run(appSaga);

export default appStore;