import topSaga from '@src/top/topSaga';
import commonSaga from '@src/common/commonSaga';
import { all } from 'redux-saga/effects'

function* appSaga() {
  yield all([
    topSaga(),
    commonSaga()
  ])
}

export default appSaga;